.register-form {
    background-color: #00c85a;
    padding: 30px 0px;
}
.register-form input {
    background-color: #070d1b;
    padding: 12px 25px;
    color: #fff;
    border-radius: 35px;
    border: none !important;
}
.register-form input:focus {
    outline: none !important;
}
.register-form input::placeholder {
    color: #fff;    
}
.register-form p {
    color: #070d1b;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 18px;
}
.register-form-submit button {
    background-color: #070d1b;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    padding: 8px 30px;
    border-radius: 50px;
    border: 2px solid #070d1b;
    transition-duration: 0.6s;
}
.register-form-submit button:hover {
    border: 2px solid #070d1b;
    background: transparent;
    color: #070d1b !important;
  }
  .modal-feild {
    display: block !important;
  }
  .content-head h2 {
    font-size: 47px;
    font-weight: 700;
  }